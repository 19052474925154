import React from 'react';
import { graphql } from 'gatsby';
import { Link } from "gatsby"
import Layout from '../../components/layout';
import SEO from '../../components/seo';
//import Img from 'gatsby-image';
//import Button from './button';

const Members = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`gatsby`, `application`, `react`, `portfolio`]}
    />
    <>
      <nav>
        <Link to="/blog/membres" style={{ display: `inline-block`, marginRight: `1rem`}} >Src: AX</Link> {" "}
        <Link to="/blog/carmel" style={{ display: `inline-block`, marginRight: `1rem`}} >Bureau</Link> {" "}
        <Link to="/blog/fideles" style={{ display: `inline-block`, marginRight: `1rem`}} >Src: X.net</Link> {" "}
        <Link to="/blog/dcd2020" style={{ display: `inline-block`, marginRight: `1rem`}} >DCD 2020</Link> {" "}
      </nav>
    </>
    <h4>Membres adhérents sur site AX<br/><i>(/group/x-memorial/59/)</i></h4>

    <div className="member-list">
      {data.allCsvjsonJson.edges.map(member => (
        <div key={member.node.id} className="member-list__item">
          
          <div className="member-list__content">
            <h4>{member.node.Civilite} {member.node.Prenom} {member.node.Nom} ({member.node.promotion})</h4>
            <div className="member-list__excerpt">
              {}
            </div>
          </div>
        </div>
      ))}
    </div>
  </Layout>
);

export default Members;
// ...your graphql query

export const csvjsonQuery = graphql`
  query {
    allCsvjsonJson(sort: { order: DESC, fields: [promotion] }) {
      edges {
        node {
          id
          Civilite
          Prenom
          Nom
          promotion
        }
      }
    }
  }
`;